import React from 'react'
import './assets/css/style.css'
import Logo from './assets/images/majuwan-dark-logo.svg'
import Apple from './assets/images/apple.svg'
import ButtonPlay from './assets/images/button-play.svg'
import Feat from './assets/images/feat-new.png'
import Feat2 from './assets/images/feat2-new.png'
import HeaderImg from './assets/images/header-img-new.png'
import Mobile from './assets/images/mobile.svg'
import PlayStore from './assets/images/playstore.svg'
import ImgGetInTouch from './assets/images/get-in-touch.png'
import ServiceIc1 from './assets/images/service-ic1.svg'
import ServiceIc2 from './assets/images/service-ic2.svg'
import ServiceIc3 from './assets/images/service-ic3.svg'
import IconFestival from './assets/images/festival_black_48dp.svg'
import IconCookie from './assets/images/cookie_black_48dp.svg'
import IconCake from './assets/images/cake_black_48dp.svg'
import Strawberry from './assets/images/strawberry.svg'
import IconVerified from './assets/images/verified_black_48dp.svg'
import i18n from './i18n'
import { Trans, useTranslation } from 'react-i18next'
import IconContact from './assets/images/contact_page_black_48dp.svg'
import IconEmail from './assets/images/email_black_48dp.svg'
import './assets/css/lightbox.css'
import Product1 from './assets/images/products/_DSC4805-s.jpg'
import Product1Big from './assets/images/products/_DSC4805.jpg'
import Product2 from './assets/images/products/_DSC4806-s.jpg'
import Product2Big from './assets/images/products/_DSC4806.jpg'
import Product3 from './assets/images/products/_DSC4808-s.jpg'
import Product3Big from './assets/images/products/_DSC4808.jpg'
import Product4 from './assets/images/products/_DSC4812-s.jpg'
import Product4Big from './assets/images/products/_DSC4812.jpg'
import Product5 from './assets/images/products/_DSC4816-s.jpg'
import Product5Big from './assets/images/products/_DSC4816.jpg'
import Product6 from './assets/images/products/_DSC4836-s.jpg'
import Product6Big from './assets/images/products/_DSC4836.jpg'
import Product7 from './assets/images/products/_DSC4829-s.jpg'
import Product7Big from './assets/images/products/_DSC4829.jpg'
import Product8 from './assets/images/products/_DSC4832-s.jpg'
import Product8Big from './assets/images/products/_DSC4832.jpg'
import Product9 from './assets/images/products/_DSC4835-s.jpg'
import Product9Big from './assets/images/products/_DSC4835.jpg'

interface Props {
  title: string
}

const Home: React.FC<Props> = ({ title }) => {
  const a = 'abc'
  return <div>{testBody()}</div>
}

function testBody() {
  return (
    <div>
      <header className='_header_grover_grocery_wrapper'>
        <nav className='navbar navbar-expand-lg navbar-light bg-light _grover_grocery_navbar'>
          <div className='container'>
            <div className='_logo_wrap'>
              <a className='_logo_link' href='#'>
                <img src={Logo} alt='Logo' className='_grover_grocery_logo' />
              </a>
            </div>
            <button
              className='navbar-toggler _toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarSupportedContent'>
              <ul className='navbar-nav mx-auto mb-2 mb-lg-0 _grover_grocery_list'>
                <li className='nav-item _grover_grocery_list_item'>
                  <a className='nav-link _active _grover_grocery_list_link' aria-current='page' href='#'>
                    {i18n.t('sidebar.menu.home')}
                  </a>
                </li>
                <li className='nav-item _grover_grocery_list_item'>
                  <a className='nav-link _grover_grocery_list_link' href='#about_us'>
                    {i18n.t('sidebar.menu.aboutUs')}
                  </a>
                </li>
                <li className='nav-item _grover_grocery_list_item'>
                  <a className='nav-link _grover_grocery_list_link' href='#all_products' tabIndex={1} aria-disabled='true'>
                    {i18n.t('sidebar.menu.products')}
                  </a>
                </li>
                <li className='nav-item _grover_grocery_list_item'>
                  <a className='nav-link _grover_grocery_list_link' href='#contact_us' tabIndex={1} aria-disabled='true'>
                    {i18n.t('sidebar.menu.contactUs')}
                  </a>
                </li>
              </ul>
              <ul className='_search_btn_list'>
                <li className='_search_btn_list_item'>
                  <form className='_nav_search_form'>
                    <span className='_search_ic'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
                        <path
                          fill='#8B8B8B'
                          d='M15.212 14.573l-3.808-3.96A6.44 6.44 0 0012.92 6.46 6.467 6.467 0 006.46 0 6.467 6.467 0 000 6.46a6.467 6.467 0 006.46 6.46 6.39 6.39 0 003.701-1.169l3.837 3.99a.838.838 0 001.191.023.844.844 0 00.023-1.19zM6.46 1.685a4.78 4.78 0 014.775 4.775 4.78 4.78 0 01-4.775 4.775A4.78 4.78 0 011.685 6.46 4.78 4.78 0 016.46 1.685z'
                        />
                      </svg>
                    </span>
                    <input className='_nav_search_input' type='text' placeholder='Search' />
                    <span className='_cart_ic'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
                        <path
                          fill='#3C3737'
                          fillRule='evenodd'
                          d='M1.014 10.084a.464.464 0 01.45-.576h21.072c.302 0 .523.283.45.576L20.059 21.79a.927.927 0 01-.9.703H4.84a.927.927 0 01-.9-.703L1.015 10.084zm11.736 3.541a.75.75 0 00-1.5 0v4.75a.75.75 0 001.5 0v-4.75zm4.319-.561a.75.75 0 01.448.961l-1.624 4.464a.75.75 0 11-1.41-.513l1.625-4.464a.75.75 0 01.96-.448zm-9.177.448a.75.75 0 10-1.41.513l1.625 4.464a.75.75 0 101.41-.513l-1.625-4.464z'
                          clipRule='evenodd'
                        />
                        <path
                          stroke='#3C3737'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M18.5 10.5l-4.911-6.422a2 2 0 00-3.178 0L5.5 10.5'
                        />
                      </svg>
                    </span>
                  </form>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className='_header_grover_grocery_wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='_header_grover_grocery_content'>
                  <div className='_header_grover_grocery_content_top_txt'>
                    <h3 className='_header_grover_grocery_content_top_title'>{i18n.t('nudge.home.header')}</h3>
                    <span className='_header_top_txt_img'>
                      <img src={IconVerified} alt='Image' className='_strawberry_img' />
                    </span>
                  </div>
                  <div className='_header_grover_grocery_content_txt'>
                    <h1 className='_header_grover_grocery_content_title'>
                      <Trans i18nKey='page.home.header.title.new' components={{ special: <span className='_header_red_clr'></span> }} />
                    </h1>
                    <p className='_header_grover_grocery_content_para'>{i18n.t('page.home.header.subtitle')}</p>
                  </div>
                  <div className='_header_grover_grocery_btn_wrap'>
                    <div className='row'>
                      <div className='col-xl-4 col-lg-5 col-md-3 col-sm-4 col-4 mx-auto ms-md-0'>
                        <div className='_header_grover_grocery_btn'>
                          <a href='https://forms.gle/QW3ZeNb33rErWQaT7' className='_header_now_btn' target='_blank'>
                            {i18n.t('button.orderNow')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='_header_grover_grocery_content_img'>
                  <img src={HeaderImg} alt='Header Image' className='_header_grover_grocery_img rounded-4' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id='about_us' className='_service_grover_grocery_wrapper'>
        <div className='_service_grover_grocery_wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='_service_grover_grocery_heading'>
                  <h5 className='_service_grover_grocery_heading_title'>{i18n.t('whatWeServe.title')}</h5>
                  <h2 className='_service_grover_grocery_heading_title2'>{i18n.t('whatWeServe.description')}</h2>
                </div>
              </div>
            </div>
            <div className='_service_grover_grocery_content_wrap'>
              <div className='row'>
                <div className='col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto'>
                  <div className='row'>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                      <div className='_service_grover_grocery_content_inner'>
                        <div className='_service_grover_grocery_content_bg'></div>
                        <div className='_service_grover_grocery_content'>
                          <div className='_service_grover_grocery_content_ic'>
                            <img src={IconCookie} alt='Image' className='_service_grover_grocery_content_img' />
                          </div>
                          <div className='_service_grover_grocery_content_txt'>
                            <h3 className='_service_grover_grocery_content_title'>{i18n.t('whatWeServe.option1.title')}</h3>
                            <p className='_service_grover_grocery_content_para'>{i18n.t('whatWeServe.option1.description')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                      <div className='_service_grover_grocery_content_inner'>
                        <div className='_service_grover_grocery_content_bg'></div>
                        <div className='_service_grover_grocery_content'>
                          <div className='_service_grover_grocery_content_ic'>
                            <img src={IconCake} alt='Image' className='_service_grover_grocery_content_img' />
                          </div>
                          <div className='_service_grover_grocery_content_txt'>
                            <h3 className='_service_grover_grocery_content_title'>{i18n.t('whatWeServe.option2.title')}</h3>
                            <p className='_service_grover_grocery_content_para'>{i18n.t('whatWeServe.option2.description')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                      <div className='_service_grover_grocery_content_inner'>
                        <div className='_service_grover_grocery_content_bg'></div>
                        <div className='_service_grover_grocery_content'>
                          <div className='_service_grover_grocery_content_ic'>
                            <img src={IconFestival} alt='Image' className='_service_grover_grocery_content_img' />
                          </div>
                          <div className='_service_grover_grocery_content_txt'>
                            <h3 className='_service_grover_grocery_content_title'>{i18n.t('whatWeServe.option3.title')}</h3>
                            <p className='_service_grover_grocery_content_para'>{i18n.t('whatWeServe.option3.description')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='_feature_grover_grocery_wrapper'>
        <div className='_feature_grover_grocery_wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
                <div className='_feature_grover_grocery_content_img'>
                  <img src={Feat} alt='Image' className='_feature_grover_img' />
                </div>
              </div>
              <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
                <div className='_feature_grover_grocery_content'>
                  <div className='_feature_grover_grocery_content_txt'>
                    <h5 className='_feature_grover_grocery_content_title'>{i18n.t('whyChooseUs.text')}</h5>
                    <h2 className='_feature_grover_grocery_content_title2'>{i18n.t('whyChooseUs.title')}</h2>
                    <p className='_feature_grover_grocery_content_para'>{i18n.t('whyChooseUs.description')}</p>
                  </div>
                  <div className='_feature_grover_grocery_content_btn'>
                    <a href='#all_products' className='_feature_content_btn'>
                      {i18n.t('button.exploreNow')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='_feature_2_grover_grocery_wrapper'>
        <div className='_feature_2_grover_grocery_wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 order-last order-lg-first'>
                <div className='_feature_2_grover_grocery_content'>
                  <div className='_feature_2_grover_grocery_content_txt'>
                    <h5 className='_feature_2_grover_grocery_content_title'>{i18n.t('products.text')}</h5>
                    <h2 className='_feature_2_grover_grocery_content_title2'>{i18n.t('products.title')}</h2>
                    <p className='_feature_2_grover_grocery_content_para'>{i18n.t('products.description')}</p>
                  </div>
                  <div className='_feature_2_grover_grocery_content_btn'>
                    <a href='#all_products' className='_feature_2_content_btn'>
                      {i18n.t('button.exploreNow')}
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='_feature_2_grover_grocery_content_img'>
                  <img src={Feat2} alt='Image' className='_feature_2_grover_img rounded-4' />
                </div>
              </div>
              <div id='all_products' className='lightbox-gallery'>
                <div className='container'>
                  <div className='intro'>
                    <h2 className='text-center _feature_2_grover_grocery_content_title2'>{i18n.t('products.exploreMore.text')}</h2>
                    <p className='text-center _feature_2_grover_grocery_content_para'>{i18n.t('products.exploreMore.description')}</p>
                  </div>
                  <div className='row photos'>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product1Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product1} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product2Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product2} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product3Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product3} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product4Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product4} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product5Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product5} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product6Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product6} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product7Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product7} />
                      </a>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 item'>
                      <a href={Product8Big} data-lightbox='photos'>
                        <img className='img-fluid' src={Product8} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='contact_us' className='_cta_grover_grocery_wrapper'>
        <div className='_cta_grover_grocery_wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='_cta_grover_grocery_content_img'>
                  <img src={ImgGetInTouch} alt='Image' className='_cta_grover_grocery_img' />
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='_cta_grover_grocery_content'>
                  <div className='_cta_grover_grocery_content_txt'>
                    <h5 className='_cta_grover_grocery_content_title'>{i18n.t('contactUs.text')}</h5>
                    <h2 className='_cta_grover_grocery_content_title2'>{i18n.t('contactUs.title')}</h2>
                    <p className='_cta_grover_grocery_content_para'>{i18n.t('contactUs.description')}</p>
                    <p className='_cta_grover_grocery_content_para'>{i18n.t('contactUs.description2')}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xxl-8 col-xl-10 col-lg-10 col-md-8 col-sm-10 col-12 mx-sm-auto mx-md-0'>
                    <div className='_cta_grover_grocery_content_btn'>
                      <a href='https://forms.gle/QW3ZeNb33rErWQaT7' target='_blank' className='_app_btn'>
                        <span className='_app_ic'>
                          <img src={IconContact} alt='Google form' />
                        </span>{' '}
                        {i18n.t('contractUs.form.name')}
                      </a>
                      <a href='mailto:info@majuwan.com' target='_blank' className='_app_btn'>
                        <span className='_app_ic'>
                          <img src={IconEmail} alt='Google form' />
                        </span>{' '}
                        {i18n.t('button.email')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className='_footer_grover_grocery_wrapper'>
        <div className='_footer_grover_grocery_wrap'>
          <div className='container'>
            <div className='_footer_grover_grocery_top'>
              <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                  <div className='_footer_grover_grocery_logo_wrap'>
                    <div className='_footer_grover_grocery_logo'>
                      <img src={Logo} alt='logo' className='_footer_logo_img' />
                    </div>
                    <div className='_footer_grover_grocery_content_logo'>
                      <p className='_footer_grover_grocery_content_logo_txt'>{i18n.t('page.home.footer.subtitle')}</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>
                  <div className='_footer_grover_grocery_content_wrap'>
                    <div className='row'>
                      <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                        <div className='_footer_grover_grocery_content'>
                          <h4 className='_footer_grover_grocery_content_title'>{i18n.t('company.text')}</h4>
                          <div className='_footer_grover_grocery_content_list'>
                            <ul className='_footer_grover_grocery_list'>
                              <li className='_footer_grover_grocery_list_item'>
                                <a href='#about_us' className='_footer_grover_grocery_list_link'>
                                  {i18n.t('sidebar.menu.aboutUs')}
                                </a>
                              </li>
                              <li className='_footer_grover_grocery_list_item'>
                                <a href='#blog' className='_footer_grover_grocery_list_link'>
                                  {i18n.t('sidebar.menu.blog')}
                                </a>
                              </li>
                              <li className='_footer_grover_grocery_list_item'>
                                <a href='#all_products' className='_footer_grover_grocery_list_link'>
                                  {i18n.t('sidebar.menu.products')}
                                </a>
                              </li>
                              <li className='_footer_grover_grocery_list_item'>
                                <a
                                  href='https://goo.gl/maps/FqZKSLni3nZTeZcQ6'
                                  target='_blank'
                                  className='_footer_grover_grocery_list_link'
                                >
                                  {i18n.t('button.locationMap')}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                        <div className='_footer_grover_grocery_content'>
                          <h4 className='_footer_grover_grocery_content_title'>{i18n.t('services.text')}</h4>
                          <div className='_footer_grover_grocery_content_list'>
                            <ul className='_footer_grover_grocery_list'>
                              <li className='_footer_grover_grocery_list_item'>
                                <a href='#' className='_footer_grover_grocery_list_link'>
                                  {i18n.t('button.orderTracking')}
                                </a>
                              </li>
                              <li className='_footer_grover_grocery_list_item'>
                                <a href='#' className='_footer_grover_grocery_list_link'>
                                  {i18n.t('button.wishList')}
                                </a>
                              </li>
                              <li className='_footer_grover_grocery_list_item'>
                                <a href='#' className='_footer_grover_grocery_list_link'>
                                  {i18n.t('footer.tnc')}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                        <div className='_footer_grover_grocery_content'>
                          <h4 className='_footer_grover_grocery_content_title'>{i18n.t('contactUs.text')}</h4>
                          <div className='_footer_grover_grocery_content'>
                            <p className='_footer_grover_grocery_content_txt'>{i18n.t('contactUs.subscribe')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='_footer_grover_grocery_bottom'>
              <hr className='_footer_divider' />
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='_footer_grover_grocery_copyright'>
                    <p className='_footer_grover_grocery_copyright_txt'>{i18n.t('footer.reserved')}</p>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='_footer_grover_grocery_condition'>
                    <p className='_footer_grover_grocery_condition_txt'>
                      <a href='' className='_footer_grover_grocery_condition_link'>
                        {i18n.t('footer.tnc')}
                      </a>
                    </p>
                    <p className='_footer_grover_grocery_condition_txt'>
                      <a href='' className='_footer_grover_grocery_condition_link'>
                        {i18n.t('footer.privacy')}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
