import React from 'react'
import logo from './logo.svg'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './Home'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home title='Hello' />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
