import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationsEN from './translations/en.json'
import translationsID from './translations/en-MY.json'

export const resources = {
  en: {
    translation: translationsEN
  },
  id: {
    translation: translationsID
  },
  ms: {
    translation: translationsID
  },
  my: {
    translation: translationsID
  }
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backendå
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'id',
    debug: false,
    supportedLngs: ['id', 'en', 'ms', 'my'],
    lng: localStorage.getItem('i18nextLng') || 'en', // will change the language based on the localstorage i18nextLng language value or default to id
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
